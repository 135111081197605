import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApexAxisChartSeries } from 'ng-apexcharts';
import { NgxSpinnerService } from 'ngx-spinner';

import { AppOptionsService, ErrorsHandlingService } from '@core/services';
import { generateCardColor, calculateXAxisSteps } from '@core/helpers';
import { DayByFilter, HoursByFilter } from '@core/enum';

import { DashboardsInterface } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DashboardQpsService {

  public realQPSChartSeries: any[] = [];
  public limitedRealQPSSeries: ApexAxisChartSeries = [];
  public realQPSChartOptions = null;

  public bidQPSChartSeries: any[] = [];
  public limitedBidQPSSeries: ApexAxisChartSeries = [];
  public bidQPSChartOptions = null;

  public bidQpsTotal: { [key: string]: number } = {};
  public realQpsTotal: { [key: string]: number } = {};
  public maxIAxisBidQPSValue: number;

  private apiUrl = '';

  constructor(
    private http: HttpClient,
    private options: AppOptionsService,
    private errorsHandlingService: ErrorsHandlingService,
    private loadingService: NgxSpinnerService,
  ) {
    this.apiUrl = this.options.getApiUrl();
  }

  public setDefaultValuesBeforeRequest(): void {
    this.realQPSChartSeries = [];
    this.limitedRealQPSSeries = [];
    this.realQPSChartOptions = null;
    this.realQpsTotal = {};
    this.bidQPSChartSeries = [];
    this.limitedBidQPSSeries = [];
    this.bidQPSChartOptions = null;
    this.bidQpsTotal = {};
    this.maxIAxisBidQPSValue = 0;
  }


  public onToggleRealQPSCardHandler(data: DashboardsInterface.ICard[]): void {
    const enabledCards: string[] = data
      .filter((card: DashboardsInterface.ICard) => card.enabled)
      .map((card: DashboardsInterface.ICard) => card.name);

    this.limitedRealQPSSeries = this.realQPSChartSeries.filter(series => enabledCards.includes(series.name));
  }

  public onToggleBidQPSCardHandler(data: DashboardsInterface.ICard[]): void {
    const enabledCards: string[] = data
      .filter((card: DashboardsInterface.ICard) => card.enabled)
      .map((card: DashboardsInterface.ICard) => card.name);

    this.limitedBidQPSSeries = this.bidQPSChartSeries.filter(series => enabledCards.includes(series.name));
  }

  public getRealQPSDashboardDataHandler(reqData: { date: string; region: string; }): void {
    this.loadingService.show();
    this.getQPSChart(reqData, 'real')
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(res => {
        const chart = this.transformToQPSChartData(res);
        this.realQPSChartSeries = chart.series;
        this.limitedRealQPSSeries = JSON.parse(JSON.stringify(chart.series));
        this.realQpsTotal = chart.total;
        if (this.findLargestRealQPSValue(res.data) > res.qpsLimit) {
          chart.chartOptions.yaxis.max = this.roundToNearestValue(this.findLargestRealQPSValue(res.data));
        } else {
          chart.chartOptions.yaxis.max = this.roundToNearestValueByQPSLimit(res.qpsLimit + 1000, 100);
        }
        chart.chartOptions.annotations.yaxis[0].y = res.qpsLimit;
        chart.chartOptions.xaxis.tickAmount = this.calculateXaxisTickAmount(res.data, reqData.date);
        this.realQPSChartOptions = chart.chartOptions;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  public getBidQPSDashboardDataHandler(reqData: { date: string; region: string; }): void {
    this.loadingService.show();
    this.getQPSChart(reqData, 'bid')
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(res => {
        const chart = this.transformToQPSChartData(res);
        this.bidQPSChartSeries = chart.series;
        this.limitedBidQPSSeries = JSON.parse(JSON.stringify(chart.series));
        this.bidQpsTotal = chart.total;
        if (this.findLargestBidQPSValue(res.data) > res.qpsLimit) {
          chart.chartOptions.yaxis.max = this.roundToNearestValue(this.findLargestBidQPSValue(res.data));
        } else {
          chart.chartOptions.yaxis.max = this.roundToNearestValueByQPSLimit(res.qpsLimit + 1000, 100);
        }
        chart.chartOptions.annotations.yaxis[0].y = res.qpsLimit;
        chart.chartOptions.xaxis.tickAmount = this.calculateXaxisTickAmount(res.data, reqData.date);
        this.bidQPSChartOptions = chart.chartOptions;
      },
        error => this.errorsHandlingService.handleError(error));
  }

  private getIntervalMinutes(selectedGroupById: string): number {
    switch (selectedGroupById) {
      case 'last_hour':
        return 5;
      case 'last_6_hours':
        return 15;
      case 'last_12_hours':
        return 30;
      case 'last_24_hours':
        return 60;
      case 'today':
        return 60;
      case 'yesterday':
        return 60;
      case '2_days':
        return 240;
      case '3_days':
        return 360;
      case '4_days':
        return 480;
      case '5_days':
        return 720;
      case '6_days':
        return 720;
      case 'last_7_days':
        return 1440;
      default:
        return 1440;
    }
  }

  private calculateTickAmount(elementCount: number, days: number, startDate: string, endDate: string, displayHours: number) {
    if (new Date(startDate) >= new Date(endDate)) {
      throw new Error('Please check the dates listed: the start date must be before the end date.');
    }

    const recordsPerHour = 60 / 2;
    const totalRecords = recordsPerHour * 24 * days;

    const recordsPerInterval = recordsPerHour * displayHours;

    const tickAmount = totalRecords / recordsPerInterval;

    return tickAmount;
  }

  private calculateXaxisTickAmount(array: { [key: string]: string | number }[], selectedDate: string) {
    if (['last_hour', 'last_6_hours', 'last_12_hours', 'last_24_hours', 'today', 'yesterday'].includes(selectedDate)) {
      return calculateXAxisSteps(array, this.getIntervalMinutes(selectedDate));
    } else {
      return this.calculateTickAmount(
        array.length,
        DayByFilter[selectedDate],
        (array[0].date as string),
        (array[array.length - 1].date as string),
        HoursByFilter[selectedDate]
      );
    }
  }

  private roundToNearestValueByQPSLimit(num: number, unit: number) {
    return Math.ceil(num / unit) * unit;
  }

  private findLargestBidQPSValue(array: { [key: string]: number | string }[]) {
    return array.reduce((max, item) => {
      return Math.max(max, (item.demand_bid_qps as number), (item.supply_bid_qps as number));
    }, 0);
  }

  private findLargestRealQPSValue(array: { [key: string]: number | string }[]) {
    return array.reduce((max, item) => {
      return Math.max(max, (item.incoming_requests as number), (item.outgoing_requests as number));
    }, 0);
  }

  private roundToNearestValue(number: number) {
    // Define the powers of 10 for the target values
    const powersOf10 = [0.1, 1, 10, 100, 1000, 10000, 100000, 1000000, 10000000, 100000000, 1000000000];

    // Calculate the target values dynamically based on the powers of 10
    const targetValues = powersOf10.reduce((acc, val) => {
      acc.push(
        val * 0.25,
        val * 0.5,
        val * 1,
        val * 1.5,
        val * 2,
        val * 2.5,
        val * 3,
        val * 3.5,
        val * 4,
        val * 4.5,
        val * 5
      );
      return acc;
    }, []);

    if (number < 0.1) {
      return 0.1;
    }

    for (let i = 0; i < targetValues.length - 1; i++) {
      if (number < targetValues[i]) {
        return targetValues[i];
      }
    }

    return targetValues[targetValues.length - 1];
  }

  private transformToQPSChartData(chart: DashboardsInterface.IDataQPSChartRes): DashboardsInterface.IChartTransformData {
    const dynamicFields = Object.keys(chart.config).filter(
      (key) => key !== 'date',
    );

    chart.data = chart.data.map(item => {
      item.date = new Date(item.date + 'Z').getTime();
      return item;
    });

    const transformedData = chart.data.map((item) => ({
      x: item.date,
      y: dynamicFields.map((field) => Number(item[field])),
    }));

    const series = dynamicFields.map((field, index) => {
      const name = chart.config[field].name;
      const color = generateCardColor(field);

      const chartData = transformedData.map((item) => ({
        x: item.x,
        y: item.y[index],
      }));

      return {
        field,
        name,
        color,
        data: chartData,
      };
    });

    const colors = series.map((series) => ({
      name: series.name,
      color: series.color,
    }));

    const limitedSeries = series.slice(0, 3);

    const roundedQpsLimit = Math.round(chart.qpsLimit);

    const chartOptions: Partial<DashboardsInterface.ChartOptions> = {
      chart: {
        type: 'area',
        height: 500,
        zoom: {
          enabled: false,
        }
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 24,
        labels: {
          show: true,
          formatter: function (value) {
            const formatter = new Intl.DateTimeFormat('en-US', {
              day: '2-digit',
              month: 'short',
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
              timeZone: 'UTC',
            });
            const formattedDate = formatter.format(new Date(value));
            return formattedDate;
          },
        },
      },
      plotOptions: {},
      stroke: {
        curve: 'smooth',
        width: 3
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.3,
          stops: [0, 100]
        }
      },
      annotations: {
        yaxis: [{
          y: 50000,
          strokeDashArray: 0,
          borderColor: '#000',
          label: {
            text: `QPS Plan ${chart.qpsLimit}`,
            style: {
              color: '#fff',
              background: '#00E396'
            }
          }
        }]
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true
      },
      yaxis: {
        opposite: false,
        min: 0,
        max: 160000,
        tickAmount: 10,
        labels: {
          formatter: function (val) {
            return val.toFixed(0) + ' QPS';
          }
        }
      },
      colors: [],
    };

    const total = chart.total;

    return {
      series,
      colors,
      limitedSeries,
      chartOptions,
      total,
    };
  }

  private getQPSChart(filter: { date: string, region: string }, type: string): Observable<DashboardsInterface.IDataQPSChartRes> {
    return this.http.post<DashboardsInterface.IDataQPSChartRes>(`${this.apiUrl}/analytics-dashboards/qps/${type}`, filter)
      .pipe(catchError(this.errorsHandlingService.processError));
  }
}
